/* eslint react/prop-types: 0 */
import React from "react";
import PropTypes from "prop-types";

import Layout from "./components/layout";
import SEO from "./components/seo";
import { 
  formatPrice, 
  getTranslatedHeadline, 
  getTranslatedDescription
} from "../../helpers/propertyHelper";
import { translateStatic } from "../../helpers/translationHelper";
import * as theme1Styles from "../theme1/scss/theme1.module.scss";

import Media from "./detail/common/Media";
import BasicInformation from "./detail/property/BasicInformation";
import Feature from "./detail/common/Feature";
import Image from "./detail/common/Image";
import Document from "./detail/common/Document";
import Video from "./detail/common/Video";
import OpenStreetMap from "../../common/OpenStreetMap";

const Theme1PropertyDetails = (props) => {
  const { pageContext, result } = props;
  const { property, locale } = pageContext;
  const {
    remarso: {
      domainByURL: {
        website: {
          name: siteName,
          setting: display_settings,
          company: { currency: company_currency },
        },
      },
    },
    defaultLocaleKey: defaultLocale,
  } = result;

  const {
    display_listing_location_map
  } = display_settings;

  const headline = getTranslatedHeadline(property, locale, defaultLocale);
  const description = getTranslatedDescription(property, locale, defaultLocale);

  let seoTitle = `${property.reference}`;
  if (headline) {
    seoTitle = `${headline}-${property.reference}`;
  }

  let metaTitle = '';
  if (headline) {
    metaTitle = `${headline}-${siteName}`;
  }

  return (
    <Layout>
      <SEO title={seoTitle} metaTitle={metaTitle} />

      <div className={theme1Styles.detailsHeaderC}>
        <div className={theme1Styles.wrapper}>
          <div className={`${theme1Styles.detailsHeader}`}>
            <div className={`${theme1Styles.hLeft}`}>
              <h2 dangerouslySetInnerHTML={{ __html: headline }}></h2>
              <span className={theme1Styles.loc}>{property.location}</span>
            </div>
            <div className={`${theme1Styles.hRight}`}>
              <span className={`${theme1Styles.detailsPrc}`}>
                {formatPrice(property, company_currency)}
              </span>
            </div>
          </div>
        </div>
      </div>

      {(() => {
        if (property.pictures.length > 0) {
          return (
            <Media pictures={property.pictures} />
          );
        }
      })()}

      <div className={`${theme1Styles.mainContent}`}>
        <div className={theme1Styles.wrapper}>
          <BasicInformation 
            property={property} 
            locale={locale} 
            defaultLocale={defaultLocale} 
          />

          {(() => {
            if (property.services.length > 0 || property.amenities.length > 0) {
              return (
                <div className={`${theme1Styles.propDetailsSec}`}>
                  <Feature
                    services={property.services}
                    amenities={property.amenities}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {description.trim() && <div className={`${theme1Styles.propDetailsSec}`}>
            <h3>
              {translateStatic("description", locale, defaultLocale)}
            </h3>
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
          </div>}

          {(() => {
            if (property.pictures.length > 0) {
              return (
                <div className={`${theme1Styles.propDetailsSec}`}>
                  <Image
                    pictures={property.pictures}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (property.videos.length > 0) {
              return (
                <div className={`${theme1Styles.propDetailsSec}`}>
                  <Video
                    videos={property.videos}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (property.documents.length > 0) {
              return (
                <div className={`${theme1Styles.propDetailsSec}`}>
                  <Document
                    documents={property.documents}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {display_listing_location_map && property.latitude && property.longitude && 
          <div className={`${theme1Styles.propDetailsSec}`} style={{ height: '300px' }}>
            <OpenStreetMap 
              listingType="property" 
              listings={[property]} 
              latitude={parseFloat(property.latitude)} 
              longitude={parseFloat(property.longitude)} 
              zoom={12} 
              infoWindow={false} 
              moveEvent={false}
            />
          </div>}
        </div>
      </div>
    </Layout>
  );
};

export default Theme1PropertyDetails;

Theme1PropertyDetails.propTypes = {
  pageContext: PropTypes.object.isRequired,
  result: PropTypes.object,
};
