import React from "react";
import PropTypes from "prop-types";

import { getTranslatedValue, translateStatic } from "../../../../helpers/translationHelper";
import * as theme4Styles from "../../scss/theme4.module.scss";

const BasicInformation = ({ property, locale, defaultLocale }) => {
    return (
        <table className={`${theme4Styles.table} ${theme4Styles.infoTable}`}>
            <tbody>
                {(() => {
                    if (!property.property_type) return null;

                    const propertyType = getTranslatedValue(
                        property.property_type.translations,
                        "name",
                        locale,
                        defaultLocale
                    );

                    if (propertyType) {
                        return (
                            <tr>
                                <td>{translateStatic("property_type", locale, defaultLocale)}</td>
                                <td>{propertyType}</td>
                            </tr>
                        );
                    }
                })()}

                {(() => {
                    if (!property.tenure) return null;

                    const tenure = getTranslatedValue(
                        property.tenure.translations,
                        "name",
                        locale,
                        defaultLocale
                    );
                    
                    if (tenure) {
                        return (
                            <tr>
                                <td>{translateStatic("tenure", locale, defaultLocale)}</td>
                                <td>{tenure}</td>
                            </tr>
                        );
                    }
                })()}

                {(() => {
                    if (!property.status) return null;

                    const status = getTranslatedValue(
                        property.status.translations,
                        "name",
                        locale,
                        defaultLocale
                    );

                    if (status) {
                        return (
                            <tr>
                                <td>{translateStatic("status", locale, defaultLocale)}</td>
                                <td>{status}</td>
                            </tr>
                        );
                    }
                })()}

                {(() => {
                    if (!property.unit_type[0]) return null;

                    const unitType = getTranslatedValue(
                        property.unit_type[0].translations,
                        "name",
                        locale,
                        defaultLocale
                    );
                    
                    if (unitType) {
                        return (
                            <tr>
                                <td>{translateStatic("unit_type", locale, defaultLocale)}</td>
                                <td>{unitType}</td>
                            </tr>
                        );
                    }
                })()}

                {property.floor && <tr>
                    <td>{translateStatic("total_floor", locale, defaultLocale)}</td>
                    <td>{property.floor}</td>
                </tr>}

                {property.city && <tr>
                    <td>{translateStatic("city", locale, defaultLocale)}</td>
                    <td>{property.city}</td>
                </tr>}

                {property.district && <tr>
                    <td>{translateStatic("district", locale, defaultLocale)}</td>
                    <td>{property.district}</td>
                </tr>}

                {property.state && <tr>
                    <td>{translateStatic("state", locale, defaultLocale)}</td>
                    <td>{property.state}</td>
                </tr>}

                {property.country && property.country.country_name && <tr>
                    <td>{translateStatic("country", locale, defaultLocale)}</td>
                    <td>{property.country.country_name}</td>
                </tr>}

                {property.built_in_area_value && property.measurement_unit && <tr>
                    <td>{translateStatic("built_area", locale, defaultLocale)}</td>
                    <td>{property.built_in_area_value + ' ' + (property.measurement_unit.name || '')}</td>
                </tr>}
                    
                {property.internal_area_value && property.measurement_unit && <tr>
                    <td>{translateStatic("internal_area", locale, defaultLocale)}</td>
                    <td>{property.internal_area_value + ' ' + (property.measurement_unit.name || '')}</td>
                </tr>}

                {property.land_area_value && property.measurement_unit && <tr>
                    <td>{translateStatic("land_area", locale, defaultLocale)}</td>
                    <td>{property.land_area_value + ' ' + (property.measurement_unit.name || '')}</td>
                </tr>}

                {property.energy_class && <tr>
                    <td>{translateStatic("energy_class", locale, defaultLocale)}</td>
                    <td>{property.energy_class}</td>    
                </tr>}

                {property.permit_number && <tr>
                    <td>{translateStatic("permit_number", locale, defaultLocale)}</td>
                    <td>{property.permit_number}</td>    
                </tr>}
            </tbody>
        </table>
    );
}

BasicInformation.propTypes = {
    property: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired
};

export default BasicInformation;