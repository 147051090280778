/* eslint react/prop-types: 0 */
import React from "react";
import PropTypes from "prop-types";

import Layout from "./components/layout";
import SEO from "./components/seo";
import { 
  formatPrice, 
  getTranslatedHeadline, 
  getTranslatedDescription
} from "../../helpers/propertyHelper";
import { translateStatic } from "../../helpers/translationHelper";
import * as theme5Styles from "./scss/theme5.module.scss";

import Media from "./detail/common/Media";
import BasicInformation from "./detail/property/BasicInformation";
import Feature from "./detail/common/Feature";
import Document from "./detail/common/Document";
import Video from "./detail/common/Video";
import Contact from "./components/Contact";
import OpenStreetMap from "../../common/OpenStreetMap";

import bed from "./images/bed.svg";
import bathtub from "./images/bathtub.svg";
import tagicon from "./images/tag.png";

const Theme5PropertyDetails = (props) => {
  const { pageContext, result } = props;
  const { property, locale } = pageContext;
  const {
    remarso: {
      domainByURL: {
        website: {
          name: siteName,
          setting: display_settings,
          company: { currency: company_currency },
        },
      },
    },
    defaultLocaleKey: defaultLocale,
  } = result;

  const {
    display_listing_contact_form,
    display_listing_location_map,
  } = display_settings;

  const headline = getTranslatedHeadline(property, locale, defaultLocale);
  const description = getTranslatedDescription(property, locale, defaultLocale);

  let seoTitle = `${property.reference}`;
  if (headline) {
    seoTitle = `${headline}-${property.reference}`;
  }

  let metaTitle = '';
  if (headline) {
    metaTitle = `${headline}-${siteName}`;
  }

  return (
    <Layout>
      <SEO title={seoTitle} metaTitle={metaTitle} />
      <div className={`${theme5Styles.mainContent} ${theme5Styles.pt0}`}>
        <div className={theme5Styles.wrapper}>
          <div className={`${theme5Styles.prodDetailsHead}`}>
            <h2 dangerouslySetInnerHTML={{ __html: headline }}></h2>
            <span className={theme5Styles.location}>{property.location}</span>
            <div className={`${theme5Styles.propInfos}`}>
              <div className={`${theme5Styles.infos}`}>
                <img src={bed} alt="" />
                {property.bedrooms} Beds
              </div>
              <div className={`${theme5Styles.infos}`}>
                <img src={bathtub} alt="" />
                {property.bathrooms} Bath
              </div>
            </div>
          </div>

          <div className={`${theme5Styles.detailsImages}`}>
            <div className={`${theme5Styles.detailsPrice}`}>
              <span className={`${theme5Styles.tag}`}>
                <img src={tagicon} alt="" />
              </span>
              {formatPrice(property, company_currency)}
            </div>
            {(() => {
              if (property.pictures.length > 0) {
                return (
                  <Media
                    pictures={property.pictures}
                  />
                );
              }
            })()}
          </div>
          <div className={`${theme5Styles.propDetailsSec}`}>
            <h3>{translateStatic("details", locale, defaultLocale)}</h3>

            <BasicInformation
              property={property}
              locale={locale}
              defaultLocale={defaultLocale}
            />

            {description.trim() && <p dangerouslySetInnerHTML={{ __html: description }}></p>}
          </div>

          {(() => {
            if (property.services.length > 0 || property.amenities.length > 0) {
              return (
                <div className={theme5Styles.propDetailsSec}>
                  <Feature
                    services={property.services}
                    amenities={property.amenities}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (property.videos.length > 0) {
              return (
                <div
                  className={`${theme5Styles.propDetailsSec} ${theme5Styles.propVideoSec}`}
                >
                  <Video
                    videos={property.videos}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (property.documents.length > 0) {
              return (
                <div className={`${theme5Styles.propDetailsSec}`}>
                  <Document
                    documents={property.documents}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {display_listing_location_map && property.latitude && property.longitude && 
          <div className={`${theme5Styles.propDetailsSec}`} style={{ height: '300px' }}>
            <OpenStreetMap 
              listingType="property" 
              listings={[property]} 
              latitude={parseFloat(property.latitude)} 
              longitude={parseFloat(property.longitude)} 
              zoom={12} 
              infoWindow={false} 
              moveEvent={false}
            />
          </div>}

          {(() => {
            if (display_listing_contact_form) {
              return (
                <section
                  className={`${theme5Styles.homeContainet} ${theme5Styles.py40} ${theme5Styles.mt40}`}
                >
                  <h2 className={`${theme5Styles.secTl}`}>
                    {translateStatic("contact", locale, defaultLocale)}
                  </h2>
                  <Contact />
                </section>
              );
            }
          })()}
        </div>
      </div>
    </Layout>
  );
};

export default Theme5PropertyDetails;

Theme5PropertyDetails.propTypes = {
  pageContext: PropTypes.object.isRequired,
  result: PropTypes.object,
};
