import React from "react";
import PropTypes from "prop-types";

import { getTranslatedValue, translateStatic } from "../../../../helpers/translationHelper";
import * as theme5Styles from "../../scss/theme5.module.scss";

const BasicInformation = ({ property, locale, defaultLocale }) => {
    const { measurement_unit } = property;

    return (
        <div className={`${theme5Styles.propertyInfos}`}>
            <div className={`${theme5Styles.infoRow}`}>
                {(() => {
                    if (!property.property_type) return null;

                    const propertyType = getTranslatedValue(
                        property.property_type.translations,
                        "name",
                        locale,
                        defaultLocale
                    );
                    
                    if (propertyType) {
                        return (
                            <div className={`${theme5Styles.colm}`}>
                                <div className={`${theme5Styles.infos}`}>
                                    <span className={`${theme5Styles.tl}`}>
                                        {translateStatic("property_type", locale, defaultLocale)}:
                                    </span>
                                    <span className={`${theme5Styles.desc}`}>
                                        {propertyType}
                                    </span>
                                </div>
                            </div>
                        );
                    }
                })()}

                {(() => {
                    if (!property.tenure) return null;

                    const tenure = getTranslatedValue(
                        property.tenure.translations,
                        "name",
                        locale,
                        defaultLocale
                    );

                    if (tenure) {
                        return (
                            <div className={`${theme5Styles.colm}`}>
                                <div className={`${theme5Styles.infos}`}>
                                    <span className={`${theme5Styles.tl}`}>
                                        {translateStatic("tenure", locale, defaultLocale)}:
                                    </span>
                                    <span className={`${theme5Styles.desc}`}>
                                        {tenure}
                                    </span>
                                </div>
                            </div>
                        );
                    }
                })()}

                {(() => {
                    if (!property.status) return null;

                    const status = getTranslatedValue(
                        property.status.translations,
                        "name",
                        locale,
                        defaultLocale
                    );

                    if (status) {
                        return (
                            <div className={`${theme5Styles.colm}`}>
                                <div className={`${theme5Styles.infos}`}>
                                    <span className={`${theme5Styles.tl}`}>
                                        {translateStatic("status", locale, defaultLocale)}:
                                    </span>
                                    <span className={`${theme5Styles.desc}`}>
                                        {status}
                                    </span>
                                </div>
                            </div>
                        );
                    }
                })()}

                {(() => {
                    if (!property.unit_type[0]) return null;

                    const unitType = getTranslatedValue(
                        property.unit_type[0].translations,
                        "name",
                        locale,
                        defaultLocale
                    );
                    
                    if (unitType) {
                        return (
                            <div className={`${theme5Styles.colm}`}>
                                <div className={`${theme5Styles.infos}`}>
                                    <span className={`${theme5Styles.tl}`}>
                                        {translateStatic("unit_type", locale, defaultLocale)}:
                                    </span>
                                    <span className={`${theme5Styles.desc}`}>
                                        {unitType}
                                    </span>
                                </div>
                            </div>
                        );
                    }
                })()}

                {property.floor && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("total_floor", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {property.floor}
                        </span>
                    </div>
                </div>}

                {property.city && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("city", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {property.city}
                        </span>
                    </div>
                </div>}

                {property.district && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("district", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {property.district}
                        </span>
                    </div>
                </div>}

                {property.state && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("state", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {property.state}
                        </span>
                    </div>
                </div>}

                {property.country && property.country.country_name && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("country", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {property.country.country_name}
                        </span>
                    </div>
                </div>}

                {property.built_in_area_value && measurement_unit && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("built_area", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {property.built_in_area_value + ' ' + (measurement_unit.name || '')}
                        </span>
                    </div>
                </div>}

                {property.internal_area_value && measurement_unit && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("internal_area", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {property.internal_area_value + ' ' + (measurement_unit.name || '')}
                        </span>
                    </div>
                </div>}

                {property.land_area_value && measurement_unit && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("land_area", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {property.land_area_value + ' ' + (measurement_unit.name || '')}
                        </span>
                    </div>
                </div>}

                {property.energy_class && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("energy_class", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {property.energy_class}
                        </span>
                    </div>
                </div>}

                {property.permit_number && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("permit_number", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {property.permit_number}
                        </span>
                    </div>
                </div>}
            </div>
        </div>
    );
};

BasicInformation.propTypes = {
    property: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired
};

export default BasicInformation;