import React from "react";
import PropTypes from "prop-types";

import Theme1PropertyDetails from "../themes/theme1/theme1PropertyDetails";
import Theme2PropertyDetails from "../themes/theme2/theme2PropertyDetails";
import Theme3PropertyDetails from "../themes/theme3/theme3PropertyDetails";
import Theme4PropertyDetails from "../themes/theme4/theme4PropertyDetails";
import Theme5PropertyDetails from "../themes/theme5/theme5PropertyDetails";
import WithThemeLoading from "../hoc/withThemeLoading";

const PropertyDetail = (props) => {
  /** Begin: Theme Switcher */
  const templateId =
    process.env.GATSBY_TEST_THEME === "0"
      ? props.pageContext.template.id
      : process.env.GATSBY_TEST_THEME;
  const themes = [
    Theme1PropertyDetails,
    Theme2PropertyDetails,
    Theme3PropertyDetails,
    Theme4PropertyDetails,
    Theme5PropertyDetails,
  ];
  const ThemeLoading =
    parseInt(templateId) > 0
      ? WithThemeLoading(themes[parseInt(templateId) - 1])
      : WithThemeLoading(Theme1PropertyDetails);
  /** End: Theme Switcher */

  return <ThemeLoading {...props} />;
};

PropertyDetail.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default PropertyDetail;
