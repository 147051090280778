import React from "react";
import PropTypes from "prop-types"

import { getTranslatedValue, translateStatic } from "../../../../helpers/translationHelper";
import * as theme2Styles from "../../scss/theme2.module.scss";

import sellingPriceIcon from "../../../../images/selling-price.svg";
import propertyTypeIcon from "../../../../images/property-type.svg";
import bedroomIcon from "../../../../images/bed.png";
import bathroomIcon from "../../../../images/bathtub.png";
import builtAreaIcon from "../../../../images/built-area.svg";
import landAreaIcon from "../../../../images/plans.png";
import internalAreaIcon from "../../../../images/interior-design.svg";
import tenureIcon from "../../../../images/house.svg";
import energyIcon from "../../../../images/energy-class.png";
import statusIcon from "../../../../images/status.png";
import permitIcon from "../../../../images/permit.png";

const BasicInformation = ({ property, locale, defaultLocale }) => {
    return (
        <div className={`${theme2Styles.propDetailsSec}`}>
            <h3>Property Details</h3>
            <div className={`${theme2Styles.propDetailsFeatures}`}>
                <div className={`${theme2Styles.rw}`}>
                    {property.selling_price_formatted && <div className={theme2Styles.colm}>
                        <div className={`${theme2Styles.feBox}`}>
                            <img alt="image" src={sellingPriceIcon} />
                            <span className={theme2Styles.t}>
                                {translateStatic("selling_price", locale, defaultLocale)}
                            </span>
                            <span className={theme2Styles.n}>
                                {property.selling_price_formatted}
                            </span>
                        </div>
                    </div>}

                    {(() => {
                        if (!property.property_type) return null;

                        const propertyType = getTranslatedValue(
                            property.property_type.translations, 
                            'name', 
                            locale, 
                            defaultLocale
                        );

                        if (propertyType) {
                            return (
                                <div className={theme2Styles.colm}>
                                    <div className={`${theme2Styles.feBox}`}>
                                        <img alt="image" src={propertyTypeIcon} />
                                        <span className={theme2Styles.t}>
                                            {translateStatic("property_type", locale, defaultLocale)}
                                        </span>
                                        <span className={theme2Styles.n}>
                                            {propertyType}
                                        </span>
                                    </div>
                                </div>
                            );
                        }
                    })()}

                    {(() => {
                        if (!property.tenure) return null;

                        const tenure = getTranslatedValue(
                            property.tenure.translations, 
                            'name', 
                            locale, 
                            defaultLocale
                        );

                        if (tenure) {
                            return (
                                <div className={theme2Styles.colm}>
                                    <div className={`${theme2Styles.feBox}`}>
                                        <img alt="image" src={tenureIcon} />
                                        <span className={theme2Styles.t}>
                                            {translateStatic("tenure", locale, defaultLocale)}
                                        </span>
                                        <span className={theme2Styles.n}>
                                            {tenure}
                                        </span>
                                    </div>
                                </div>
                            );
                        }
                    })()}

                    {(() => {
                        if (!property.status) return null;

                        const status = getTranslatedValue(
                            property.status.translations, 
                            'name', 
                            locale, 
                            defaultLocale
                        );

                        if (status) {
                            return (
                                <div className={theme2Styles.colm}>
                                    <div className={`${theme2Styles.feBox}`}>
                                        <img alt="image" src={statusIcon} />
                                        <span className={theme2Styles.t}>
                                            {translateStatic("status", locale, defaultLocale)}
                                        </span>
                                        <span className={theme2Styles.n}>
                                            {status}
                                        </span>
                                    </div>
                                </div>
                            );
                        }
                    })()}

                    {property.bedrooms && <div className={theme2Styles.colm}>
                        <div className={`${theme2Styles.feBox}`}>
                            <img alt="image" src={bedroomIcon} />
                            <span className={theme2Styles.t}>
                                {translateStatic("bedrooms", locale, defaultLocale)}
                            </span>
                            <span className={theme2Styles.n}>
                                {property.bedrooms}
                            </span>
                        </div>
                    </div>}

                    {property.bathrooms && <div className={theme2Styles.colm}>
                        <div className={`${theme2Styles.feBox}`}>
                            <img alt="image" src={bathroomIcon} />
                            <span className={theme2Styles.t}>
                                {translateStatic("bathrooms", locale, defaultLocale)}
                            </span>
                            <span className={theme2Styles.n}>
                                {property.bathrooms}
                            </span>
                        </div>
                    </div>}

                    {property.built_in_area_value && property.measurement_unit && <div className={theme2Styles.colm}>
                        <div className={`${theme2Styles.feBox}`}>
                            <img alt="image" src={builtAreaIcon} />
                            <span className={theme2Styles.t}>
                                {translateStatic("built_area", locale, defaultLocale)}
                            </span>
                            <span className={theme2Styles.n}>
                                {property.built_in_area_value + ' ' + (property.measurement_unit.name || '')}
                            </span>
                        </div>
                    </div>}

                    {property.internal_area_value && property.measurement_unit && <div className={theme2Styles.colm}>
                        <div className={`${theme2Styles.feBox}`}>
                            <img alt="image" src={internalAreaIcon} />
                            <span className={theme2Styles.t}>
                                {translateStatic("internal_area", locale, defaultLocale)}
                            </span>
                            <span className={theme2Styles.n}>
                                {property.internal_area_value + ' ' + (property.measurement_unit.name || '')}
                            </span>
                        </div>
                    </div>}

                    {property.land_area_value && property.measurement_unit && <div className={theme2Styles.colm}>
                        <div className={`${theme2Styles.feBox}`}>
                            <img alt="image" src={landAreaIcon} />
                            <span className={theme2Styles.t}>
                                {translateStatic("land_area", locale, defaultLocale)}
                            </span>
                            <span className={theme2Styles.n}>
                                {property.land_area_value + ' ' + (property.measurement_unit.name || '')}
                            </span>
                        </div>
                    </div>}

                    {property.energy_class && <div className={theme2Styles.colm}>
                        <div className={`${theme2Styles.feBox}`}>
                            <img alt="image" src={energyIcon} />
                            <span className={theme2Styles.t}>
                                {translateStatic("energy_class", locale, defaultLocale)}
                            </span>
                            <span className={theme2Styles.n}>
                                {property.energy_class}
                            </span>
                        </div>
                    </div>}

                    {property.permit_number && <div className={theme2Styles.colm}>
                        <div className={`${theme2Styles.feBox}`}>
                            <img alt="image" src={permitIcon} />
                            <span className={theme2Styles.t}>
                                {translateStatic("permit_number", locale, defaultLocale)}
                            </span>
                            <span className={theme2Styles.n}>
                                {property.permit_number}
                            </span>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
};

BasicInformation.propTypes = {
    property: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired
};

export default BasicInformation;
