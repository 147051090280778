/* eslint react/prop-types: 0 */
import React from "react";
import PropTypes from "prop-types";

import ContactFormContainer from "../../common/ContactFormContainer";

import Layout from "./components/layout";
import SEO from "./components/seo";
import { 
  formatPrice, 
  getTranslatedHeadline, 
  getTranslatedDescription 
} from "../../helpers/propertyHelper";
import { 
  getTranslatedValue, 
  translateStatic 
} from "../../helpers/translationHelper";
import * as theme3Styles from "../theme3/scss/theme3.module.scss";

import Media from "./detail/common/Media";
import Feature from "./detail/common/Feature";
import Document from "./detail/common/Document";
import Video from "./detail/common/Video";
import OpenStreetMap from "../../common/OpenStreetMap";

import profilePic from "./images/profile-pic.jpg";

const Theme3PropertyDetails = (props) => {
  const { pageContext, result } = props;
  const { property, locale } = pageContext;
  const {
    remarso: {
      domainByURL: {
        website: {
          name: siteName,
          webform_assigned_to_user,
          webform_assigned_to_group,
          setting: display_settings,
          company: { currency: company_currency },
        },
      },
    },
    defaultLocaleKey: defaultLocale,
  } = result;

  const {
    display_listing_contact_form,
    display_listing_assigned_to_detail,
    display_listing_location_map,
  } = display_settings;

  const headline = getTranslatedHeadline(property, locale, defaultLocale);
  const description = getTranslatedDescription(property, locale, defaultLocale);

  let seoTitle = `${property.reference}`;
  if (headline) {
    seoTitle = `${headline}-${property.reference}`;
  }

  let metaTitle = '';
  if (headline) {
    metaTitle = `${headline}-${siteName}`;
  }

  return (
    <Layout>
      <SEO title={seoTitle} metaTitle={metaTitle} />
      <div className={`${theme3Styles.mainContent} ${theme3Styles.pt0}`}>
        <div className={theme3Styles.wrapper}>
          <div className={`${theme3Styles.detailsPageRow}`}>
            <div className={`${theme3Styles.detailsColmL}`}>
              {(() => {
                if (property.pictures.length > 0) {
                  return (
                    <Media
                      pictures={property.pictures}
                    />
                  );
                }
              })()}

              <div className={`${theme3Styles.detailsHeader}`}>
                <div className={`${theme3Styles.detailsPriceC}`}>
                  <span className={`${theme3Styles.detailsPrc}`}>
                    {formatPrice(property, company_currency)}
                  </span>
                  <ul className={theme3Styles.features}>
                    <li>
                      {property.bedrooms}
                      <abbr> bds</abbr>
                    </li>
                    <li>
                      {property.bathrooms}
                      <abbr> ba</abbr>
                    </li>
                    <li>
                      {property.internal_area_value}
                      <abbr>
                        {" "}
                        {property.measurement_unit
                          ? property.measurement_unit.name
                          : ""}
                      </abbr>
                    </li>
                  </ul>
                </div>
                <h2 dangerouslySetInnerHTML={{ __html: headline }}></h2>
                <span className={theme3Styles.loc}>{property.location}</span>
                <span className={theme3Styles.category}>
                  {(() => {
                    const propertyType = property.property_type
                      ? getTranslatedValue(
                          property.property_type.translations,
                          "name",
                          locale,
                          defaultLocale
                        )
                      : null;
                    const category = property.category
                      ? getTranslatedValue(
                          property.category.translations,
                          "name",
                          locale,
                          defaultLocale
                        )
                      : null;

                    if (propertyType && !category) {
                      return propertyType;
                    }
                    if (!propertyType && category) {
                      return category;
                    }
                    if (propertyType && category) {
                      return `${propertyType} for ${category}`;
                    }
                  })()}
                </span>
              </div>
              {(() => {
                if (
                  property.services.length > 0 ||
                  property.amenities.length > 0
                ) {
                  return (
                    <div className={theme3Styles.propDetailsSec}>
                      <Feature
                        services={property.services}
                        amenities={property.amenities}
                        locale={locale}
                        defaultLocale={defaultLocale}
                      />
                    </div>
                  );
                }
              })()}

              {description.trim() && <div className={`${theme3Styles.propDetailsSec}`}>
                  <h3>
                    {translateStatic(
                      "description",
                      locale,
                      defaultLocale
                    )}
                  </h3>
                  <p dangerouslySetInnerHTML={{ __html: description }}></p>
                </div>}

              {(() => {
                if (property.videos.length > 0) {
                  return (
                    <div
                      className={`${theme3Styles.propDetailsSec} ${theme3Styles.propVideoSec}`}
                    >
                      <Video
                        videos={property.videos}
                        locale={locale}
                        defaultLocale={defaultLocale}
                      />
                    </div>
                  );
                }
              })()}

              {(() => {
                if (property.documents.length > 0) {
                  return (
                    <div className={`${theme3Styles.propDetailsSec}`}>
                      <Document
                        documents={property.documents}
                        locale={locale}
                        defaultLocale={defaultLocale}
                      />
                    </div>
                  );
                }
              })()}

              {display_listing_location_map && property.latitude && property.longitude && 
              <div className={`${theme3Styles.propDetailsSec}`} style={{ height: '300px' }}>
                <OpenStreetMap 
                  listingType="property" 
                  listings={[property]} 
                  latitude={parseFloat(property.latitude)} 
                  longitude={parseFloat(property.longitude)} 
                  zoom={12} 
                  infoWindow={false} 
                  moveEvent={false}
                />
              </div>}
            </div>
            {(() => {
              if (display_listing_contact_form) {
                return (
                  <div className={`${theme3Styles.detailsColmR}`}>
                    <div className={theme3Styles.contactUs}>
                      <h6>
                        {translateStatic("contact_us", locale, defaultLocale)}
                        <small>
                          {translateStatic(
                            "contact_form_text",
                            locale,
                            defaultLocale
                          )}
                        </small>
                      </h6>
                      <ContactFormContainer />
                      {(() => {
                        let agent =
                          property.agent ||
                          webform_assigned_to_user ||
                          webform_assigned_to_group?.leader;
                        if (agent && display_listing_assigned_to_detail) {
                          let phone_number = "";
                          phone_number += agent.countryCode
                            ? agent.countryCode + "-"
                            : "";
                          phone_number += agent.mobile_phone
                            ? agent.mobile_phone
                            : "";
                          return (
                            <div className={`${theme3Styles.contactAgent}`}>
                              <div className={theme3Styles.photo}>
                                <img
                                  alt="phone"
                                  src={agent.profile_image || profilePic}
                                />
                              </div>
                              <div className={theme3Styles.details}>
                                <span className={theme3Styles.nm}>
                                  {agent.name}
                                </span>
                                <span className={theme3Styles.txt}>
                                  <strong>
                                    {translateStatic(
                                      "email",
                                      locale,
                                      defaultLocale
                                    )}
                                    :{" "}
                                  </strong>
                                  {agent.email}
                                </span>
                                <span className={theme3Styles.txt}>
                                  <strong>
                                    {translateStatic(
                                      "phone",
                                      locale,
                                      defaultLocale
                                    )}
                                    :{" "}
                                  </strong>
                                  {phone_number}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      })()}
                    </div>
                  </div>
                );
              }
            })()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Theme3PropertyDetails;

Theme3PropertyDetails.propTypes = {
  pageContext: PropTypes.object.isRequired,
  result: PropTypes.object,
};
