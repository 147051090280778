/* eslint react/prop-types: 0 */
import React from "react";
import PropTypes from "prop-types";

import ContactFormContainer from "../../common/ContactFormContainer";
import Layout from "./components/layout";
import SEO from "./components/seo";
import { 
  formatPrice, 
  getTranslatedHeadline, 
  getTranslatedDescription
} from "../../helpers/propertyHelper";
import { translateStatic } from "../../helpers/translationHelper";
import * as theme4Styles from "./scss/theme4.module.scss";

import Media from "./detail/common/Media";
import BasicInformation from "./detail/property/BasicInformation";
import Feature from "./detail/common/Feature";
import Document from "./detail/common/Document";
import Video from "./detail/common/Video";
import OpenStreetMap from "../../common/OpenStreetMap";

import profilePic from "./images/profile-pic.jpg";

const Theme4PropertyDetails = (props) => {
  const { pageContext, result } = props;
  const { property, locale } = pageContext;
  const {
    remarso: {
      domainByURL: {
        website: {
          name: siteName,
          webform_assigned_to_user,
          webform_assigned_to_group,
          setting: display_settings,
          company: { currency: company_currency },
        },
      },
    },
    defaultLocaleKey: defaultLocale,
  } = result;

  const {
    display_listing_contact_form,
    display_listing_assigned_to_detail,
    display_listing_location_map,
  } = display_settings;

  const headline = getTranslatedHeadline(property, locale, defaultLocale);
  const description = getTranslatedDescription(property, locale, defaultLocale);

  let seoTitle = `${property.reference}`;
  if (headline) {
    seoTitle = `${headline}-${property.reference}`;
  }

  let metaTitle = '';
  if (headline) {
    metaTitle = `${headline}-${siteName}`;
  }

  return (
    <Layout>
      <SEO title={seoTitle} metaTitle={metaTitle} />
      <div className={`${theme4Styles.mainContent} ${theme4Styles.pt0}`}>
        <div className={theme4Styles.wrapper}>
          <div className={`${theme4Styles.prodDetailsHead}`}>
            <div className={`${theme4Styles.leftSec}`}>
              <h2 dangerouslySetInnerHTML={{ __html: headline }}></h2>
              <span className={theme4Styles.location}>{property.location}</span>
            </div>
            <div className={`${theme4Styles.rightSec}`}>
              <div className={`${theme4Styles.propInfos}`}>
                <div className={`${theme4Styles.infos}`}>
                  {formatPrice(property, company_currency)}
                  <span>{translateStatic("price", locale, defaultLocale)}</span>
                </div>
                <div className={`${theme4Styles.infos}`}>
                  {property.bedrooms}
                  <span>Beds</span>
                </div>
                <div className={`${theme4Styles.infos}`}>
                  {property.bathrooms}
                  <span>Bath</span>
                </div>
                <div className={`${theme4Styles.infos}`}>
                  <div className={`${theme4Styles.sqft}`}>
                    {(() => {
                      if (
                        property.internal_area_value &&
                        property.measurement_unit
                      ) {
                        return (
                          <span>
                            {property.internal_area_value}{" "}
                            {property.measurement_unit.name}
                          </span>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${theme4Styles.detailsPageRow}`}>
            <div className={`${theme4Styles.detailsColmL}`}>
              {(() => {
                if (property.pictures.length > 0) {
                  return (
                    <Media
                      pictures={property.pictures}
                    />
                  );
                }
              })()}

              <div className={`${theme4Styles.propDetailsSec} ${theme4Styles.hide799}`}>
                <BasicInformation
                  property={property}
                  locale={locale}
                  defaultLocale={defaultLocale}
                />
              </div>

              {description.trim() && <div className={`${theme4Styles.propDetailsSec}`}>
                <p dangerouslySetInnerHTML={{ __html: description }}></p>
              </div>}

              {(() => {
                if (
                  property.services.length > 0 ||
                  property.amenities.length > 0
                ) {
                  return (
                    <div className={theme4Styles.propDetailsSec}>
                      <Feature
                        services={property.services}
                        amenities={property.amenities}
                        locale={locale}
                        defaultLocale={defaultLocale}
                      />
                    </div>
                  );
                }
              })()}

              {(() => {
                if (property.videos.length > 0) {
                  return (
                    <div
                      className={`${theme4Styles.propDetailsSec} ${theme4Styles.propVideoSec}`}
                    >
                      <Video
                        videos={property.videos}
                        locale={locale}
                        defaultLocale={defaultLocale}
                      />
                    </div>
                  );
                }
              })()}

              {(() => {
                if (property.documents.length > 0) {
                  return (
                    <div className={`${theme4Styles.propDetailsSec}`}>
                      <Document
                        documents={property.documents}
                        locale={locale}
                        defaultLocale={defaultLocale}
                      />
                    </div>
                  );
                }
              })()}

              {display_listing_location_map && property.latitude && property.longitude && 
              <div className={`${theme4Styles.propDetailsSec}`} style={{ height: '300px' }}>
                <OpenStreetMap 
                  listingType="property" 
                  listings={[property]} 
                  latitude={parseFloat(property.latitude)} 
                  longitude={parseFloat(property.longitude)} 
                  zoom={12} 
                  infoWindow={false} 
                  moveEvent={false}
                />
              </div>}
            </div>
            <div
              className={`${theme4Styles.detailsColmR} ${theme4Styles.tableShow799}`}
            >
              <BasicInformation
                property={property}
                locale={locale}
                defaultLocale={defaultLocale}
              />
              
              {(() => {
                if (!display_listing_contact_form) {
                  return null;
                }

                return (
                  <div
                    className={`${theme4Styles.descSec} ${theme4Styles.sticky}`}
                  >
                    {(() => {
                      let agent =
                        property.agent ||
                        webform_assigned_to_user ||
                        webform_assigned_to_group?.leader;
                      if (agent && display_listing_assigned_to_detail) {
                        let phone_number = "";
                        phone_number += agent.countryCode
                          ? agent.countryCode + "-"
                          : "";
                        phone_number += agent.mobile_phone
                          ? agent.mobile_phone
                          : "";

                        return (
                          <React.Fragment>
                            <h3>
                              {translateStatic("agent", locale, defaultLocale)}
                            </h3>
                            <div
                              className={`${theme4Styles.contactAgent} ${theme4Styles.mt0}`}
                            >
                              <div className={theme4Styles.photo}>
                                <img
                                  alt="phone"
                                  src={agent.profile_image || profilePic}
                                />
                              </div>
                              <div className={theme4Styles.details}>
                                <span className={theme4Styles.nm}>
                                  {agent.name}
                                </span>
                                <span className={theme4Styles.txt}>
                                  <strong>
                                    {translateStatic(
                                      "email",
                                      locale,
                                      defaultLocale
                                    )}
                                    :{" "}
                                  </strong>
                                  {agent.email}
                                </span>
                                <span className={theme4Styles.txt}>
                                  <strong>
                                    {translateStatic(
                                      "phone",
                                      locale,
                                      defaultLocale
                                    )}
                                    :{" "}
                                  </strong>
                                  {phone_number}
                                </span>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      }
                    })()}

                    <div className={theme4Styles.contactUs}>
                      <h6>
                        {translateStatic("contact_us", locale, defaultLocale)}
                        <small>
                          {translateStatic(
                            "contact_form_text",
                            locale,
                            defaultLocale
                          )}
                        </small>
                      </h6>
                      <ContactFormContainer />
                    </div>
                  </div>
                );
              })()}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Theme4PropertyDetails;

Theme4PropertyDetails.propTypes = {
  pageContext: PropTypes.object.isRequired,
  result: PropTypes.object,
};
